define("transatelital/pods/components/ts-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zapGN69i",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"logo\"],[14,\"src\",\"/assets/images/logo.png\"],[14,\"alt\",\"my pic\"],[12],[13],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"Transsatelital\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "transatelital/pods/components/ts-header/template.hbs"
    }
  });

  _exports.default = _default;
});