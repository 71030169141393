define("transatelital/pods/about-us/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OYZfumsg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"route-title\"],[12],[2,\"About us\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "transatelital/pods/about-us/template.hbs"
    }
  });

  _exports.default = _default;
});