define("transatelital/pods/components/ts-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eUq4EGYX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"footer\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[10,\"strong\"],[12],[2,\"TECHNO STANDARD CORP.\"],[13],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"8345 NW 68 Street\"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"Miami, Fl 33166\"],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"tel:7862458168\"],[12],[2,\"Phone: (786) 245 - 8168\"],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"mailto:sales@transsatelital.com\"],[12],[2,\"Email: sales@transsatelital.com\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "transatelital/pods/components/ts-footer/template.hbs"
    }
  });

  _exports.default = _default;
});