define("transatelital/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F3Q5fjtr",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ts-header\",[],[[],[]],null],[2,\"\\n\"],[8,\"ts-navbar\",[],[[],[]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"main-container\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\" \\n\"],[13],[2,\"\\n\"],[8,\"ts-footer\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "transatelital/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});