define("transatelital/pods/components/ts-navbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zox/uDhL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"nav\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\"],[\"home\"]],[[\"default\"],[{\"statements\":[[2,\"home\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\"],[\"products\"]],[[\"default\"],[{\"statements\":[[2,\"products\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\"],[\"contact-us\"]],[[\"default\"],[{\"statements\":[[2,\"contact us\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"nav-link\"]],[[\"@route\"],[\"about-us\"]],[[\"default\"],[{\"statements\":[[2,\"about us\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "transatelital/pods/components/ts-navbar/template.hbs"
    }
  });

  _exports.default = _default;
});